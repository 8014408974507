"use strict";

// Slider
const slide2 = document.querySelector(".slide--2");
const slider = function () {
  const slides = document.querySelectorAll(".slide");
  const dotContainer = document.querySelector(".dots");

  let curSlide = 0;
  const maxSlide = slides.length;

  // Functions
  const createDots = function () {
    slides.forEach(function (_, i) {
      dotContainer.insertAdjacentHTML(
        "beforeend",
        `<button class="dots__dot" data-slide="${i}">&#9898;</button>`
      );
    });
  };

  const activateDot = function (slide) {
    document
      .querySelectorAll(".dots__dot")
      .forEach((dot) => dot.classList.remove("dots__dot--active"));
    document
      .querySelector(`.dots__dot[data-slide="${slide}"]`)
      .classList.add("dots__dot--active");
  };

  const goToSlide = function (slide) {
    slides.forEach((s, i) => {
      s.style.transform = `translateX(${100 * (i - slide)}%)`;
      s.style.transition = "all 1s";
    });
  };

  // Next slide
  const nextSlide = function () {
    if (curSlide === maxSlide - 1) {
      curSlide = 0;
    } else curSlide++;
    slide2.classList.remove("hidden");
    goToSlide(curSlide);
    activateDot(curSlide);
  };

  // Prev slide
  const prevSlide = function () {
    if (curSlide === 0) {
      curSlide = maxSlide - 1;
    } else curSlide--;
    slide2.classList.remove("hidden");
    goToSlide(curSlide);
    activateDot(curSlide);
  };

  const init = function () {
    goToSlide(0);
    createDots();
    activateDot(0);
  };
  init();

  // Event Handler
  document.addEventListener("keydown", function (e) {
    if (e.key === "ArrowLeft") prevSlide();
    e.key === "ArrowRight" && nextSlide();
  });

  // Mobile touch
  const slider = document.querySelector(".slider");
  let touchX;
  let touchY;
  slider.addEventListener("touchmove", function (event) {
    console.log(event.targetTouches);
    if (
      event.targetTouches[0].pageX <= touchX &&
      event.targetTouches[0].pageY == touchY
    ) {
      prevSlide();
    }
    if (
      event.targetTouches[0].pageX >= touchX &&
      event.targetTouches[0].pageY == touchY
    ) {
      nextSlide();
    }
    touchX = event.targetTouches[0].pageX;
    touchY = event.targetTouches[0].pageY;
  });

  // Dot Handler
  dotContainer.addEventListener("click", function (e) {
    if (e.target.classList.contains("dots__dot")) {
      const { slide } = e.target.dataset;
      slide2.classList.remove("hidden");
      goToSlide(slide);
      activateDot(slide);
    }
  });
};
slider();

// Rendering News
const sectionNews = document.querySelector("#news");

const getNews = async function () {
  const response = await fetch(
    `${STRAPI_URL}/api/news-sections?sort[0]=publishedAt:desc&pagination[limit]=12&populate=foto`
  );
  const newsJson = await response.json();
  const data = newsJson.data;

  const generateSection = function () {
    return `    
      <h1 class="news--h1">Ultime News<span class="bar"> |</span><span class="btn--plus"><a
          href="./news.html"> guarda di più</a></span></h1>
    `;
  };

  if (data.length > 0) {
    sectionNews.insertAdjacentHTML("afterbegin", generateSection());
  }

  const splide = document.querySelector(".splide__list");

  const generateMarkup = function (i, id) {
    return /*html*/ `

<li class="splide__slide">
    <a href="./single-news.html?id=${id}"><div class="news--content">
    <img src="${data[i].attributes.foto.data.attributes.url}" />
    <div class="news--text">
      <p class="news--date">${data[i].attributes.data}</p>
      <h4 class="news--title">${data[i].attributes.titolo}</h4>
    </div>
    </div>
    </a>
</li>
  </section>
    `;
  };
  for (const item of data.entries()) {
    let id = item[1].id;
    let markup = generateMarkup(item[0], id);
    splide.insertAdjacentHTML("afterbegin", markup);
  }
};

getNews();

// Rendering Videos

const getVideos = async function () {
  // Ottenere dati API
  const response = await fetch(
    `${STRAPI_URL}/api/video-sections?sort[0]=publishedAt:desc&pagination[limit]=3&populate=video`
  );
  const newsJson = await response.json();
  const data = newsJson.data;
  // Creare html
  const newsSection = document.querySelector("#news");
  const generateSection = function () {
    return `    
      <section id="videos" class="section section--hidden">
             <h1>Video<span class="bar"> |</span><span class="btn--plus"><a href="./news.html#link-videos"> guarda di più</a></span></h1>
      <div class="video--container"></div>
      </section>
    `;
  };

  if (data.length > 0) {
    if (newsSection) {
      newsSection.insertAdjacentHTML("afterend", generateSection());
    } else {
      main.insertAdjacentHTML("afterbegin", generateSection());
    }
  }

  const videos = document.querySelector(".video--container");

  const generateMarkup = function (i) {
    return /*html*/ `
  <div class="video--content">
    <video controls>
    <source src="${data[i].attributes.video.data.attributes.url}#t=0.001" type="video/mp4">
    </video>
    <div class="video--text">
    <p class="video--date">
    ${data[i].attributes.data}
    </p>
    <h4 class="video--title">${data[i].attributes.titolo}</h4>
    </div>
  </div>
    `;
  };
  for (const item of data.entries()) {
    let markup = generateMarkup(item[0]);
    videos.insertAdjacentHTML("beforeend", markup);
  }
};

getVideos();
